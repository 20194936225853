import "./RileySatellite.css";

import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

const RileySatellite = (props) => {
  return (
    <>
      <div className="rs-container">
        <div></div>
      </div>
    </>
  );
};

export default RileySatellite;
