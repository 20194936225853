import "./RileyMap.css";

import React, { useEffect, useRef, useState } from "react";

import CipherMapStyle from "../../CipherMapStyle.json";
import GoogleMapReact from "google-map-react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import RileyKeyCode from "../RileyNav/RileyKeyCode";
import RileyMarker from "./RileyMarker/RileyMarker";
import RileySatellite from "./RileyMapControls/RileySatellite";
import { connect } from "react-redux";
import { setMapApi } from "../../redux/actions/map_actions";
import store from "../../redux/store";
import { updateMarker } from "../../redux/actions/marker_actions";
import useGeolocation from "react-hook-geolocation";

const mapStateToProps = (state) => ({
  marker: state.marker,
});

const RileyMap = (props) => {
  const DEFCENTER = {
    lat: 33.609383,
    lng: -117.8035335,
  };
  const googleMap = useRef();
  const googleMaps = useRef();
  const geolocation = useGeolocation();
  const { marker, setMapApi } = props;
  const onBoundsChange = (center, zoom, bounds, marginBounds) => {};
  function loadMapControls() {
    const keycodeinput = document.createElement("div");
    const satelliteControl = document.createElement("div");

    ReactDOM.render(
      <Provider store={store}>
        <RileyKeyCode />
      </Provider>,
      keycodeinput
    );
    ReactDOM.render(
      <Provider store={store}>
        <RileySatellite />
      </Provider>,
      satelliteControl
    );

    googleMap.current.controls[
      googleMaps.current.ControlPosition.TOP_LEFT
    ].push(keycodeinput);
    googleMap.current.controls[
      googleMaps.current.ControlPosition.TOP_RIGHT
    ].push(satelliteControl);
  }
  return (
    <>
      {!geolocation.error && (
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              googleMap.current = map;
              googleMaps.current = maps;
              setMapApi(map);
              loadMapControls();
            }}
            defaultCenter={DEFCENTER}
            defaultZoom={14}
            onBoundsChange={onBoundsChange}
            yesIWantToUseGoogleMapApiInternals
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: true,
              gestureHandling: "greedy",
              styles: CipherMapStyle,
            }}
          >
            {marker && marker.visible && (
              <RileyMarker
                lat={marker.lat}
                lng={marker.lng}
                title={marker.title}
                description={marker.description}
                headTo={marker.headTo}
                hintImg={marker.hintImg}
              >
                {marker.title}
              </RileyMarker>
            )}
          </GoogleMapReact>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, { updateMarker, setMapApi })(RileyMap);
