import "./RileyKeyCode.css";

import * as Yup from "yup";

import {
  ALISO_PEAK,
  OCEAN_INSTITUTE,
  PARK,
  SALT_CREEK,
  START,
} from "../../Locations";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { Alert } from "react-bootstrap";
import React from "react";
import SnakeError from "../Forms/SnakeError/SnakeError";
import SnakeField from "../Forms/SnakeFields/SnakeField";
import SnakeFormError from "../Forms/SnakeError/SnakeFormError";
import SnakeSuccess from "../Forms/SnakeError/SnakeSuccess";
import { connect } from "react-redux";
import { updateMarker } from "../../redux/actions/marker_actions";
import { useState } from "react";

const mapStateToProps = (state) => ({
  marker: state.marker,
  map: state.map,
});

const ZOOM_LEVEL = 15;

const RileyKeyCode = (props) => {
  const { updateMarker, map } = props;
  const [alert, setAlert] = useState({
    title: "",
    description: "",
    type: "",
    show: false,
  });
  function successAlert(keyCode) {
    setAlert({
      title: "Success",
      description: `${keyCode} is valid code!`,
      type: "SUCCESS",
      show: true,
    });
  }
  function handleKeyCode(keyCode) {
    switch (keyCode) {
      case "START":
        successAlert(keyCode);
        map.panTo({ lat: parseFloat(START.lat), lng: parseFloat(START.lng) });
        map.setZoom(ZOOM_LEVEL);
        updateMarker(START);
        break;
      case "PARK":
        successAlert(keyCode);
        map.panTo({
          lat: parseFloat(PARK.lat),
          lng: parseFloat(PARK.lng),
        });
        map.setZoom(ZOOM_LEVEL);
        updateMarker(PARK);
        break;
      case "MOON":
        successAlert(keyCode);
        map.panTo({
          lat: parseFloat(OCEAN_INSTITUTE.lat),
          lng: parseFloat(OCEAN_INSTITUTE.lng),
        });
        map.setZoom(ZOOM_LEVEL);
        updateMarker(OCEAN_INSTITUTE);
        break;
      case "CREEK":
        successAlert(keyCode);
        map.panTo({
          lat: parseFloat(SALT_CREEK.lat),
          lng: parseFloat(SALT_CREEK.lng),
        });
        map.setZoom(ZOOM_LEVEL);
        updateMarker(SALT_CREEK);
        break;
      case "BENCH":
        successAlert(keyCode);
        map.panTo({
          lat: parseFloat(ALISO_PEAK.lat),
          lng: parseFloat(ALISO_PEAK.lng),
        });
        map.setZoom(ZOOM_LEVEL);
        updateMarker(ALISO_PEAK);
        break;
      default:
        setAlert({
          title: "Invalid Code",
          description: `${keyCode} is not a valid code...`,
          type: "ERROR",
          show: true,
        });
        updateMarker(null);
    }
  }
  return (
    <>
      <div className="rkc-container">
        <Formik
          className="rkc-formik"
          initialValues={{ keyCode: "" }}
          validationSchema={Yup.object({})}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              //alert(JSON.stringify(values, null, 2));
              console.log("Values", values.keyCode);
              handleKeyCode(values.keyCode);
              setSubmitting(false);
            }, 400);
          }}
        >
          <Form>
            <SnakeField
              type="text"
              name="keyCode"
              placeholder="Secret Code"
              title="Secret Code"
            />
            <SnakeFormError name="keyCode" title="Secret Code" />
          </Form>
        </Formik>
        {alert && alert.type === "SUCCESS" && (
          <SnakeSuccess title={alert.title} description={alert.description} />
        )}
        {alert && alert.type === "ERROR" && (
          <SnakeError title={alert.title} description={alert.description} />
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, { updateMarker })(RileyKeyCode);
