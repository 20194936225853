import "./SnakeError.css";

import { FaExclamation } from "react-icons/fa";
import React from "react";

function SnakeError(props) {
  const { title, description } = props;

  return (
    <>
      <div className="sfe-root-container">
        <section className="sfe-section">
          <div className="sfe-icon-container">
            <div className="sfe-icon-div">
              <FaExclamation />
            </div>
          </div>
          <div className="sfe-text-container">
            <h2 className="sfe-text-large">{title}</h2>
            <div className="sfe-text-small">{description}</div>
          </div>
        </section>
      </div>
    </>
  );
}

export default SnakeError;
