import "./App.css";

import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import RileyMap from "./components/RileyMap/RileyMap";

function App() {
  return (
    <>
      <Router>
        <div>
          <Switch>
            <Route path="/">
              <RileyMap />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
