import "./RileyMarker.css";

import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import ReactBnbGallery from "react-bnb-gallery";

const RileyMarker = (props) => {
  const { children, title, description, headTo, hintImg } = props;
  const [show, setShow] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  return (
    <>
      <div {...props} className="rm-marker" onClick={() => setShow(true)}>
        <div className="rm-marker-scale">
          <div className="rm-marker-container">
            <div className="rm-marker-text-container">
              <span className="rm-marker-text-span">{children}</span>
            </div>
          </div>
        </div>
      </div>
      {hintImg && (
        <ReactBnbGallery
          show={showGallery}
          photos={[{ photo: hintImg }]}
          onClose={() => setShowGallery(false)}
        />
      )}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{description}</div>
          {typeof hintImg === "string" && (
            <div>
              <img
                src={hintImg}
                className="rm-hint-image"
                onClick={() => setShowGallery(true)}
              />
            </div>
          )}
          {headTo && headTo.lat && headTo.lng && (
            <div>
              Location: <b>{headTo.lat}</b> | <b>{headTo.lng}</b>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShow(false)}
            className="riley-button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RileyMarker;
