import { START } from "../../Locations";
import { UPDATE_MARKER } from "../actions/index";
const marker = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MARKER:
      return action.payload;
    default:
      return state;
  }
};

export default marker;
