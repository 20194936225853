import "./SnakeError.css";

import { FaExclamation } from "react-icons/fa";
import React from "react";
import { useFormikContext } from "formik";

function SnakeFormError(props) {
  const { name, title } = props;
  const {
    values,
    touched,
    errors,
    submitForm,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();
  return (
    <>
      {errors[name] && touched[name] ? (
        <div className="sfe-root-container">
          <section className="sfe-section">
            <div className="sfe-icon-container">
              <div className="sfe-icon-div">
                <FaExclamation />
              </div>
            </div>
            <div className="sfe-text-container">
              <h2 className="sfe-text-large">{title}</h2>
              <div className="sfe-text-small">{errors[name]}</div>
            </div>
          </section>
        </div>
      ) : null}
    </>
  );
}

export default SnakeFormError;
