import { SET_MAP_API } from "../actions/index";
const map = (state = {}, action) => {
  switch (action.type) {
    case SET_MAP_API:
      return action.payload;
    default:
      return state;
  }
};

export default map;
