import "./SnakeField.css";

import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useFormikContext } from "formik";

const SnakeField = (props) => {
  const { id, name, type, placeholder, title, help, as, mask } = props;
  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext();
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (values[name] && values[name].length > 0) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [values[name]]);
  return (
    <>
      <div className="sf-field-input-container" {...props}>
        <div>
          <div
            style={as === "textarea" ? { height: "100%" } : { height: "56px" }}
            className={cn(
              "sf-field-outer-style",
              { "sf-field-outer-style-active": isActive },
              { "sf-field-outer-style": isFilled && !errors[name] },
              {
                "sf-field-outer-style-error":
                  values[name] && values[name].length > 0 && errors[name],
              },
              {
                "sf-field-outer-style-error": errors[name] && touched[name],
              }
            )}
          >
            <label className="sf-field-label">
              <div
                className={cn(
                  "sf-field-label-inner",
                  { "sf-field-label-inner-active": isActive },
                  { "sf-field-label-inner-active": isFilled },
                  {
                    "sf-field-label-inner-error":
                      values[name] && values[name].length > 0 && errors[name],
                  }
                )}
              >
                <div className="sf-field-label-title">
                  {title ? title : placeholder}
                </div>
              </div>
              <div>
                <div
                  style={
                    isActive
                      ? { display: "flex", opacity: "1" }
                      : isFilled
                      ? { display: "flex", opacity: "1" }
                      : { display: "flex", opacity: "0" }
                  }
                >
                  {as === "textarea" ? (
                    <textarea
                      className="sf-field-textarea"
                      id={id}
                      name={name}
                      type={type}
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      onFocus={() => setIsActive(true)}
                      onBlur={() => setIsActive(false)}
                    />
                  ) : (
                    <input
                      className="sf-field-input"
                      id={id}
                      name={name}
                      type={type}
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      onFocus={() => setIsActive(true)}
                      onBlur={() => setIsActive(false)}
                    />
                  )}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div style={{ marginTop: "8px" }}>
          <div className="sf-input-help-text">{help}</div>
        </div>
      </div>
    </>
  );
};

export default SnakeField;
