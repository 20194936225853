import CAR_IMG from "./assets/images/C1.jpg";
import OCEAN_INSTITUTE_IMG from "./assets/images/O1.jpg";

export const START = {
  lat: 33.6474597,
  lng: -117.8260126,
  visible: true,
  title: "Start",
  description:
    "🏁 Welcome to the adventure! Some of these hunts will include searching for a QR code or solving a puzzle to determine a short code. The first code you see is hidden in the photo below.",
  hintImg: CAR_IMG,
};

export const PARK = {
  lat: 33.646052,
  lng: -117.843738,
  visible: true,
  title: "Aldrich Park",
  description: `
  Always old, sometimes new.

  Never sad, sometimes blue.

  Never empty, sometimes full.

  Never pushes, always pulls.
  `,
  headTo: {
    lat: 33.646052,
    lng: -117.843738,
  },
};

export const OCEAN_INSTITUTE = {
  lat: 33.4610014,
  lng: -117.7089143,
  visible: true,
  title: "Ocean Institute",
  description: `
  I stand bright and tall for all to see.
  I warn of the dangers caused by the sea. 
  I stand atop the barrier between calm and uneasy.
  What am I? 🌊
  `,
  hintImg: OCEAN_INSTITUTE_IMG,
};

export const SALT_CREEK = {
  lat: 33.473786,
  lng: -117.720742,
  visible: true,
  title: "SALT CREEK BEACH",
  description:
    "Climb to the top of the hill to find what you seek. Keep an eye for things that repeat. A five letter word twice I repeate. What am I? 🔭",
};

export const ALISO_PEAK = {
  lat: 33.50922,
  lng: -117.744481,
  visible: true,
  title: "?",
  description: "You'll need some hiking shoes!!! :) ⛰️",
  headTo: {
    lat: 33.50922,
    lng: -117.744481,
  },
};
